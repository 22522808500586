import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import "App.css"
import { Button, Col, Container, FormFeedback, Input, Label, Row } from "reactstrap"
import {
  getCompanies as onGetCompanies,
  getEvents as onGetEvents,
  getUsersPublic as onGetUsersPublic,
  updateEvent as onUpdateEvent
} from "../../store/actions"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import timeGridPlugin from "@fullcalendar/timegrid"
import "moment/locale/fr"
import UserProfileSelector from "./components/UserProfileSelector"

const Appointment = () => {
  const dispatch = useDispatch()
  const [event, setEvent] = useState(null)
  const [send, setSend] = useState(false)
  const [userProfile, setUserProfile] = useState(null)
  const [updateUser, setUpdateUser] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [dateStart, setDateStart] = useState(moment().startOf("isoWeek").format("YYYY-MM-DD"))
  const [dateEnd, setDateEnd] = useState(moment().endOf("isoWeek").format("YYYY-MM-DD"))

  const handleWeekChange = (info) => {
    const newDateStart = moment(info.start).format("YYYY-MM-DD")
    const newDateEnd = moment(info.end).format("YYYY-MM-DD")

    if (userProfile && newDateStart !== dateStart && newDateEnd !== dateEnd)  {
      setDateStart(newDateStart)
      setDateEnd(newDateEnd)
      dispatch(onGetEvents({
        user: userProfile._id,
        dateStart: moment(info.start).format("YYYY-MM-DD"),
        dateEnd: moment(info.end).format("YYYY-MM-DD"),
        company: selectedCompany
      }))
    }
  }

  const ContactsProperties = createSelector(
    (state) => state.contacts,
    (Contacts) => ({
      users: Contacts.users
    })
  )

  const CalendarProperties = createSelector(
    (state) => state.calendar,
    (Calendar) => ({
      events: Calendar.events
    })
  )

  const CompaniesProperties = createSelector(
    (state) => state.Companies,
    (Companies) => {
      return ({
        companies: Companies.companies
      })
    }
  )

  const { companies } = useSelector(CompaniesProperties)
  const { events } = useSelector(CalendarProperties)
  const { users } = useSelector(ContactsProperties)

  useEffect(() => {
    if (selectedCompany && userProfile) {
      dispatch(onGetEvents({
        user: userProfile._id,
        dateStart,
        dateEnd,
        company: selectedCompany
      }));
    }
  }, [selectedCompany, userProfile, dateStart, dateEnd]);

  useEffect(() => {

    const hasComapny = companies.filter(c => c.status === 'active').length > 0;
    if (companies && hasComapny && !selectedCompany) {
      setSelectedCompany(companies.filter(c => c.status === 'active')[0]._id)
    }

    if (companies && !hasComapny) {
      dispatch(onGetCompanies())
    }

    if (companies && hasComapny && (users.length === 0 && !selectedCompany)) {
      dispatch(onGetUsersPublic())
    } else {
      if (users.length > 0 && !userProfile && selectedCompany) {
        setUserProfile(users[0])
        dispatch(onGetEvents({
          user: users[0]._id,
          dateStart,
          dateEnd,
          company: selectedCompany
        }))
      }
    }

  }, [dispatch, userProfile, users, companies, events])

  const formik = useFormik({
    initialValues: {
      name: "",
      subject: "",
      location: "",
      message: "",
      email: "",
      phone: "",
      event: null
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Le nom est requis"),
      subject: Yup.string().required("L’objet du RDV est requis"),
      message: Yup.string().required("Message obligatoire"),
      email: Yup.string().email().required("Email obligatoire"),
      phone: Yup.string().required("Téléphone obligatoire")
    }),
    onSubmit: values => {
      values.event = event.id
      dispatch(onUpdateEvent(values))
      formik.resetForm()
      setSend(true)
    }
  })

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <i style={{ margin: "0px" }}>{moment(eventInfo.event.start).format("HH:mm")}</i>
      </>
    )
  }

  const handleEventClick = (e) => {
    const event = e.event
    setEvent({
      id: event.id,
      title: event.title,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0]
    })
  }


  const comp = companies?.find(c => c._id?.toString() === selectedCompany)
  return (
    <Container sm="12" md={{ size: 4, offset: 4 }} className={"p-3"}>
      {companies.filter(c => c.status === "active").length > 1 ? <Row>
        <Col>
          <div className={"text-left"}>
            <h4>Séléctionner une Agence</h4>
            <Input type="select" name="company" id="companySelect" onChange={(e) => {
              setSelectedCompany(e.target.value)
              dispatch(onGetUsersPublic())
            }} value={selectedCompany}>
              {companies.filter(c => c.status === "active").map((company) => (
                <option key={company._id} value={company._id}>{company.name}</option>
              ))}
            </Input>
          </div>
        </Col>
      </Row> : ""}
      <Row>
        <Col>
          <div className={"text-center"}>
            {/* <UserSelector users={users} updateUser={updateUser} setUpdateUser={setUpdateUser} userProfile={userProfile} setUserProfile={setUserProfile} /> */}
            <UserProfileSelector
              company={selectedCompany}
              dateStart={dateStart}
              dateEnd={dateEnd}
              users={users}
              updateUser={updateUser}
              setUpdateUser={setUpdateUser}
              userProfile={userProfile}
              setUserProfile={setUserProfile} />
          </div>
          <form onSubmit={formik.handleSubmit}>

            {/* Champ Sujet */}
            <div className="mb-3">
              <Label className="form-label">Nom complet</Label>
              <Input
                name="name"
                type="text"
                placeholder="votre nom et prénom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                invalid={formik.touched.name && !!formik.errors.name}
              />
              {formik.touched.name && formik.errors.name && (
                <FormFeedback type="invalid">
                  {formik.errors.name}
                </FormFeedback>
              )}
            </div>

            {/* Champ Sujet */}
            <div className="mb-3">
              <Label className="form-label">Objet du RDV</Label>
              <Input
                name="subject"
                type="text"
                placeholder="Entrez l'objet du rendez-vous"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                invalid={formik.touched.subject && !!formik.errors.subject}
              />
              {formik.touched.subject && formik.errors.subject && (
                <FormFeedback type="invalid">
                  {formik.errors.subject}
                </FormFeedback>
              )}
            </div>
            <div className="mini-calendar mb-3">
              {!event ? <FullCalendar
                  firstDay={1}
                  eventContent={renderEventContent}
                  datesSet={handleWeekChange}
                  plugins={[
                    BootstrapTheme,
                    dayGridPlugin,
                    listPlugin,
                    timeGridPlugin,
                    interactionPlugin
                  ]}
                  timeZone="local"
                  initialView="timeGridWeek"
                  slotMinTime="09:00:00"
                  slotMaxTime="18:00:00"
                  handleWindowResize={true}
                  slotDuration={"01:00:00"}
                  themeSystem="bootstrap"
                  headerToolbar={{
                    left: "prev,next today",
                    center: false,
                    right: false
                  }}
                  locale={"fr-FR"}
                  events={events
                    .filter(e => moment(e.startDate).isAfter(moment()))
                    .filter(e => !e.booked)
                    .map(e => {
                      return {
                        id: e._id,
                        title: "Disponible",
                        start: e.startDate,
                        end: e.endDate
                      }
                    })}
                  allDaySlot={false}
                  editable={false}
                  droppable={false}
                  selectable={true}
                  eventClick={handleEventClick}
                  hiddenDays={[6,0]}
                /> :
                <div>
                  <Label for="appointmentLocation">Date et Heure</Label><br />
                  <div onClick={() => setEvent(null)} className={"btn btn-info"}
                       style={{ width: "100%", textAlign: "left" }}>
                    Vous souhaitez prendre rendez vous le <br />
                    {moment(event.start).format("dddd D MMMM YYYY [à] HH[h]mm")}
                    <i style={{ marginTop: "-10px", fontSize: "20px", float: "right" }}
                       className="bx bx-window-close"></i>
                  </div>
                </div>}
            </div>

            {/* Champ Lieu */}
            <div className="mb-3">
              <Label className="form-label">Lieu</Label>
              <div>
                <div onClick={() => {
                  window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${comp?.address}, ${comp?.zipcode} ${comp?.city}`)}`)
                }} className={"btn btn-light"}
                     style={{ width: "100%", textAlign: "left" }}>
                  {comp?.address}<br />
                  {comp?.zipcode} {comp?.city}
                  <i style={{ marginTop: "-10px", fontSize: "20px", float: "right" }}
                     className="bx bx-map"></i>
                </div>
              </div>
            </div>

            {/* Champ email */}
            <div className="mb-3">
              <Label className="form-label">Email</Label>
              <Input
                name="email"
                type="email"
                placeholder="votre@adresse.fr"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                invalid={formik.touched.email && !!formik.errors.email}
              />
              {formik.touched.email && formik.errors.email && (
                <FormFeedback type="invalid">
                  {formik.errors.email}
                </FormFeedback>
              )}
            </div>

            {/* Champ téléphone */}
            <div className="mb-3">
              <Label className="form-label">Téléphone</Label>
              <Input
                name="phone"
                type="phone"
                placeholder="06xxxxxxxx"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                invalid={formik.touched.phone && !!formik.errors.phone}
              />
              {formik.touched.phone && formik.errors.phone && (
                <FormFeedback type="invalid">
                  {formik.errors.phone}
                </FormFeedback>
              )}
            </div>

            {/* Champ Message */}
            <div className="mb-3">
              <Label className="form-label">Message</Label>
              <Input
                name="message"
                type="textarea"
                placeholder="Votre message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                invalid={formik.touched.message && !!formik.errors.message}
              />
              {formik.touched.message && formik.errors.message && (
                <FormFeedback type="invalid">
                  {formik.errors.message}
                </FormFeedback>
              )}
            </div>
            {send ? <div id="alertBox" className="alert alert-success" role="alert">
                Votre message a été envoyé avec succès!
              </div>
              : ""}
            <Button className={"btn btn-primary"} type="submit">Prendre RDV</Button>
          </form>
        </Col>
      </Row>


    </Container>
  )
}

export default Appointment

