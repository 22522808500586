import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";
import { ADD_NEW_BULK_EVENT, ADD_NEW_EVENT } from "./url_helper"

export const getEvents = (payload) => {
  const {dateStart, dateEnd, user, company} = payload;
  return  get(`${url.ADD_NEW_EVENT}?start=${dateStart}&end=${dateEnd}&user=${user}&company=${company}`)
};

export const addNewEvent = availability => {
  return  post(url.ADD_NEW_EVENT, availability)
};

export const addNewBulkEvent = availabilies => {
  return  post(url.ADD_NEW_BULK_EVENT, availabilies)
};

export const deleteEvent = availability => {
  return  del(`${url.ADD_NEW_EVENT}/${availability}`)
};

export const updateEvent = availability => {
  return  put(`${url.ADD_NEW_EVENT}/${availability.event}`, availability)
};