import React, { useEffect, useState, useMemo } from "react";
import withRouter from "components/Common/withRouter";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getCompanies as onGetCompanies,
  addNewCompany as onAddNewCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
} from "store/companies/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import Companies from "../../store/companies/reducer"

const List = () => {

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const validation = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      user: "",
      company: "",
      period: "morning",
    },
    validationSchema: Yup.object({
      startDate: Yup.date().required("Date de début requise"),
      endDate: Yup.date().required("Date de fin requise"),
      user: Yup.string().required("Utilisateur requis"),
      company: Yup.string().required("Société requise"),
      period: Yup.string().required("Période requise"),
    }),
    onSubmit: (values) => {
      const bulkData = {
        startDate: values.startDate,
        endDate: values.endDate,
        user: values.user,
        company: values.company,
        period: values.period,
      };

      dispatch(createBulkAvailabilities(bulkData)); // Utiliser createBulkAvailabilities
      validation.resetForm();
      toggle();
    },
  });

  return (
    <React.Fragment>
      <button onClick={toggle} className="btn btn-success mb-3">
        Ajouter Disponibilité
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">Ajouter Disponibilité</ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <div className="mb-3">
              <Label className="form-label">Date de début</Label>
              <Input
                name="startDate"
                type="date"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.startDate}
                invalid={validation.touched.startDate && validation.errors.startDate ? true : false}
              />
              {validation.touched.startDate && validation.errors.startDate ? (
                <FormFeedback>{validation.errors.startDate}</FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Date de fin</Label>
              <Input
                name="endDate"
                type="date"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.endDate}
                invalid={validation.touched.endDate && validation.errors.endDate ? true : false}
              />
              {validation.touched.endDate && validation.errors.endDate ? (
                <FormFeedback>{validation.errors.endDate}</FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Utilisateur</Label>
              <Input
                name="user"
                type="text"
                placeholder="ID de l'utilisateur"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.user}
                invalid={validation.touched.user && validation.errors.user ? true : false}
              />
              {validation.touched.user && validation.errors.user ? (
                <FormFeedback>{validation.errors.user}</FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Société</Label>
              <Input
                name="company"
                type="text"
                placeholder="ID de la société"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.company}
                invalid={validation.touched.company && validation.errors.company ? true : false}
              />
              {validation.touched.company && validation.errors.company ? (
                <FormFeedback>{validation.errors.company}</FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Période</Label>
              <Select
                name="period"
                options={[
                  { value: 'morning', label: 'Matin' },
                  { value: 'afternoon', label: 'Après-midi' }
                ]}
                onChange={option => validation.setFieldValue("period", option.value)}
                onBlur={() => validation.setFieldTouched("period", true)}
                classNamePrefix="select"
                value={validation.values.period}
              />
              {validation.touched.period && validation.errors.period ? (
                <FormFeedback>{validation.errors.period}</FormFeedback>
              ) : null}
            </div>

            <div className="text-end">
              <button type="submit" className="btn btn-success">Enregistrer</button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(List);
